import { Component, OnInit } from '@angular/core';
import * as api from '@amc-technology/davinci-api';
import { getSearchLayout } from '../util/SearchLayout';
import { getClickToDialLayout } from '../util/ClickToDialLayout';
import { ICrmEntity } from '../Model/ICrmEntity';
import { bind } from 'bind-decorator';
import {
  Application,
  getCadPopKeys,
} from '@amc-technology/applicationangularframework';
import { IActivity } from '../Model/IActivity';
import { IActivityDetails } from '../Model/IActivityDetails';
import { IHoldActivityDetails } from '../Model/IHoldActivityDetails';
import { StorageService } from '../storage.service';
import { LoggerService } from '../logger.service';
import { IClickToDialLayout } from '../Model/IClickToDialLayout';
import { ISearchLayout, ISearchLayoutObject } from '../Model/ISearchLayout';
import { getCadDisplayConfig } from '../util/DisplayCADInfo';
import { ICadDisplayConfig } from '../Model/ICadDisplayConfig';
import {
  CHANNEL_TYPES,
  IContextualContact,
  INTERACTION_DIRECTION_TYPES,
  INTERACTION_STATES,
  ISupportedChannel,
} from '@amc-technology/davinci-api';

@Component({
  selector: 'app-home',
  templateUrl: 'home-dynamics.component.html',
})
export class HomeComponent extends Application implements OnInit {
  private maxRecordsDefault = 50;
  public searchLayout: ISearchLayout;
  private lastSearchedCTDNumber: string;
  private cadDisplayConfig: ICadDisplayConfig;
  private clickToDialLayout: IClickToDialLayout;
  private contextualContacts: { [key: string]: IContextualContact } = {};
  private phoneNumberFormat: Object;
  public quickCommentList: string[];
  protected enableAutoSave: boolean;
  public enableCallActivity: boolean;
  public activityLayout: any;
  private lastClickToDialRecords: api.SearchRecords;
  private lastClickToDialIActivityDetails: IActivityDetails = null;
  private screenpopOnAlert: boolean;
  protected cadActivityMap: Object;
  public clickToDialPhoneReformatMap: Object;
  public DisplayQuickCreate: boolean;
  protected QuickCreateEntities: any;
  public quickCommentOptionRequiredCadArray: any;
  private softphoneWidth: any;
  private channels: ISupportedChannel;
  private delayActivitySave: number;
  private garbageCollectionFrequency: number;
  private scenarioExpirationTime: number;
  private clickToDialTriggered = false;
  private phoneNumberSearchFormats: string[];

  constructor(
    private loggerService: LoggerService,
    public storageService: StorageService
  ) {
    super(loggerService.logger);
    this.storageService.syncWithLocalStorage();
    this.phoneNumberFormat = {};
    this.lastClickToDialRecords = null;
    this.screenpopOnAlert = true;
    this.lastSearchedCTDNumber = '';
    this.clickToDialPhoneReformatMap = {};
    this.enableCallActivity = true;
    this.enableAutoSave = true;
    this.cadActivityMap = {};
    this.quickCommentOptionRequiredCadArray = {};
    this.delayActivitySave = 0;
    this.garbageCollectionFrequency = 5000;
    this.scenarioExpirationTime = 10000;
    this.phoneNumberSearchFormats = [];
  }

  async ngOnInit() {
    try {
      this.logger.logDebug(
        'Dynamics - Home : START : Fetching Dynamics App Configuration'
      );
      await this.loadConfig();
      this.checkNumbOfRecentActivities();

      this.bridgeScripts = this.bridgeScripts.concat([
        this.getBridgeURL(),
        'https://amcdavincistorage.blob.core.windows.net/amc-dynamics-docs/msdyn_ciLibraryv2.js',
      ]);

      // Get width from Creators Studio
      this.softphoneWidth = parseInt(this.appConfig.variables.toolbarWidth, 10);

      const customAmcCifJSURL = String(
        this.appConfig['variables']['customAmcCifJSURL']
      );

      if (customAmcCifJSURL) {
        this.bridgeScripts = this.bridgeScripts.concat([customAmcCifJSURL]);
      }

      api.registerScreenpop(async (channelType, interactionDirection, objID, objType) => {
        const entity = {
          id: objID,
          type: objType
        };
        this.bridgeEventsService.sendEvent(
          'agentSelectedCallerInformation',
          entity
        );
      });

      const appName = this.appConfig.name;
      api.registerSetSupportedChannels(async (appName, channels) => {
        this.channels = channels[0];
        this.bridgeEventsService.sendEvent(
          'sendSupportedChannels',
          this.channels
        );
      });

      await super.ngOnInit();
      this.getActivityLayout();

      if (!isNaN(this.softphoneWidth)) {
        this.bridgeEventsService.sendEvent(
          'setDynamicsSoftphoneWidth',
          this.softphoneWidth
        );
      }

      this.bridgeEventsService.sendEvent(
        'updateActivityLayout',
        this.activityLayout
      );
      this.bridgeEventsService.sendEvent(
        'setObjects',
        this.searchLayout.objects
      );
      this.bridgeEventsService.subscribe(
        'clickToDialEntities',
        this.clickToDialEntitiesHandler
      );
      this.bridgeEventsService.subscribe(
        'clickToDial',
        this.clickToDialHandler
      );
      this.bridgeEventsService.subscribe(
        'createNewEntity',
        this.createNewEntity
      );
      this.bridgeEventsService.subscribe(
        'sendNotification',
        this.sendNotification
      );
      this.bridgeEventsService.subscribe('customEvent', this.customEvent);

      api.registerOnLogout(async () => {
        await this.removeLocalStorageOnLogout();
        await this.loggerService.logger.pushLogsAsync();
      });
      this.logger.logDebug(
        'Dynamics - Home : Configuration from Dynamics App : ' +
        JSON.stringify(this.appConfig)
      );

      this.readConfig(this.appConfig);

      // Garbage collection
      setInterval(this.garbageCollection, this.garbageCollectionFrequency);

      this.logger.logDebug(
        'Dynamics - Home : END : Fetching Dynamics App Configuration'
      );
    } catch (error) {
      this.logger.logError(
        'Dynamics - Home : ERROR : Fetching Configuration. Error Information : ' +
        JSON.stringify(error)
      );
    }
  }

  private checkNumbOfRecentActivities() {
    try {
      this.logger.logDebug(
        'Dynamics - Home : START : checkNumbOfRecentActivities'
      );
      // Removing Recent Activity items that local storage service added back
      if (this.storageService.maxRecentItems === 0) {
        this.storageService.recentScenarioIdList.splice(0, this.storageService.recentScenarioIdList.length);
      }
      this.logger.logDebug(
        'Dynamics - Home : END : checkNumbOfRecentActivities'
      );
    } catch (error) {
      this.logger.logError(
        'Dynamics - Home : ERROR : checkNumbOfRecentActivities. Error Information : ' +
        JSON.stringify(error)
      );
    }
  }

  @bind
  private garbageCollection(): void {
    try {
      this.logger.logDebug(
        'Dynamics - Home : START : Garbage Collection'
      );

      this.cleanupScenarios();

      this.logger.logDebug(
        'Dynamics - Home : END : Garbage Collection'
      );
    } catch (error) {
      this.logger.logError(
        'Dynamics - Home : ERROR : Garbage Collection. Error Information : ' +
        JSON.stringify(error)
      );
    }
  }

  private cleanupScenarios(): void {
    try {
      this.logger.logDebug(
        'Dynamics - Home : START : Cleaning Expired Scenarios'
      );

      for (const activityId in this.storageService.activityList) {
        const activity = this.storageService.activityList[activityId];

        // Check if activity is not in recent activities
        if (!this.storageService.recentActivityListContains(activity.ScenarioId)) {
          // Check if LastUpdated is older than threshold
          const date = new Date();
          if ((date.getTime() - new Date(activity.LastUpdated).getTime()) > this.scenarioExpirationTime) {
            this.deleteOrphanedActivity(activity.ScenarioId);
          }
        }
      }

      this.logger.logDebug(
        'Dynamics - Home : END : Cleaning Expired Scenarios'
      );
    } catch (error) {
      this.logger.logError(
        'Dynamics - Home : ERROR : Cleaning Expired Scenarios. Error Information : ' +
        JSON.stringify(error)
      );
    }


  }

  protected deleteOrphanedActivity(scenarioId: string): void {
    try {
      this.logger.logInformation(
        'Dynamics - Home : START : Removing Orphaned Activity With Scenario ID : ' +
        scenarioId
      );
      // this.lastClickToDialIActivityDetails = null;
      if (!this.scenarioInteractionMappings[scenarioId]) {
        this.saveAndStoreActivity(scenarioId);
      }
      this.logger.logInformation(
        'Dynamics - Home : END : Removing Orphaned Activity With Scenario ID : ' +
        scenarioId
      );
    } catch (error) {
      this.logger.logError(
        'Dynamics - Home : ERROR :  Orphaned Activity With Scenario ID : ' +
        scenarioId +
        '. Error Information : ' +
        JSON.stringify(error)
      );
    }
  }

  private readConfig(config: api.IAppConfiguration) {
    try {
      this.logger.logDebug(
        'Dynamics - Home : START : Reading Configuration from Dynamics App'
      );
      if (this.appConfig['CallActivity']['variables']['DelayActivitySave'] != null) {
        this.delayActivitySave = this.appConfig['CallActivity']['variables']['DelayActivitySave'];
      }
      if (config['CallActivity']['variables']['ActivityExpirationTime'] != null) {
        this.scenarioExpirationTime = config['CallActivity']['variables']['ActivityExpirationTime'] as number;
      }

      if (config['CallActivity']['variables']['ActivityVerificationFrequency'] != null) {
        this.garbageCollectionFrequency = config['CallActivity']['variables']['ActivityVerificationFrequency'] as number;
      }

      const configPhoneFormat = config.variables['PhoneNumberFormat'];
      if (typeof configPhoneFormat === 'string') {
        const tempFormat = String(configPhoneFormat).toLowerCase();
        this.phoneNumberFormat[tempFormat] = tempFormat;
      } else {
        this.phoneNumberFormat = configPhoneFormat;
      }
      this.quickCommentList = <string[]>(
        (config['CallActivity']
          ? config['CallActivity']['variables']['QuickComments']
          : config['variables']['QuickComments'])
      );
      this.cadActivityMap = config['CallActivity']
        ? config['CallActivity']['variables']['CADActivityMap']
        : config['variables']['CADActivityMap']
          ? config['variables']['CADActivityMap']
          : {};
      for (let i = 0; i < this.quickCommentList.length; i++) {
        this.quickCommentList[i] = this.quickCommentList[i].replace(
          /\\n/g,
          String.fromCharCode(13, 10)
        );
        this.quickCommentList[i] = this.quickCommentList[i].replace(
          /\\t/g,
          String.fromCharCode(9)
        );
      }
      const CADQuickCommentRegex = /\{\{.*?\}\}/g;
      for (let i = 0; i < this.quickCommentList.length; i++) {
        this.quickCommentOptionRequiredCadArray[i] =
          this.quickCommentList[i].match(CADQuickCommentRegex);
      }
      if (config.variables['ClickToDialPhoneReformatMap']) {
        this.clickToDialPhoneReformatMap =
          config.variables['ClickToDialPhoneReformatMap'];
      }
      if (
        config['QuickCreate'] &&
        config['QuickCreate']['variables']['QuickCreateKeyList']
      ) {
        this.QuickCreateEntities =
          config['QuickCreate']['variables']['QuickCreateKeyList'];
        this.DisplayQuickCreate =
          Object.keys(this.QuickCreateEntities).length > 0;
      } else {
        this.DisplayQuickCreate = false;
      }
      if (
        config['CallActivity'] &&
        config['CallActivity']['variables'] &&
        config['CallActivity']['variables']['CallFromObjects']
      ) {
        this.storageService.setCallFromObjects(
          config['CallActivity']['variables']['CallFromObjects']
        );
      }
      if (
        config['CallActivity'] &&
        config['CallActivity']['variables'] &&
        config['CallActivity']['variables']['RegardingObjects']
      ) {
        this.storageService.setRegardingObjects(
          config['CallActivity']['variables']['RegardingObjects']
        );
      }

      if (
        config['CallActivity'] &&
        config['CallActivity']['variables'] &&
        config['CallActivity']['variables']['Subject']
      ) {
        this.storageService.subject = config['CallActivity']['variables']['Subject'];
      }
      this.logger.logDebug(
        'Dynamics - Home : END : Reading Configuration from Dynamics App'
      );
    } catch (error) {
      this.logger.logError(
        'Dynamics - Home : ERROR : Reading Configuration. Config Info : ' +
        JSON.stringify(config) +
        '. Error Information : ' +
        JSON.stringify(error)
      );
    }
  }

  protected formatPhoneNumber(
    inputNumber: string,
    phoneNumberFormat: Object
  ): string {
    try {
      this.logger.logTrace(
        'Dynamics - Home : START : Formatting Phone Number. Input Number : ' +
        inputNumber +
        '. Configured Format : ' +
        JSON.stringify(phoneNumberFormat)
      );
      const configuredInputFormats = Object.keys(phoneNumberFormat);
      for (let index = 0; index < configuredInputFormats.length; index++) {
        let formatCheck = true;
        const inputFormat = configuredInputFormats[index];
        const outputFormat = phoneNumberFormat[inputFormat];
        if (inputFormat.length === inputNumber.length) {
          const arrInputDigits = [];
          let outputNumber = '';
          let outputIncrement = 0;
          if (
            (inputFormat.match(/x/g) || []).length !==
            (outputFormat.match(/x/g) || []).length
          ) {
            continue;
          }
          for (let j = 0; j < inputFormat.length; j++) {
            if (inputFormat[j] === 'x') {
              arrInputDigits.push(j);
            } else if (
              inputFormat[j] !== '?' &&
              inputNumber[j] !== inputFormat[j]
            ) {
              formatCheck = false;
              break;
            }
          }
          if (formatCheck) {
            for (let j = 0; j < outputFormat.length; j++) {
              if (outputFormat[j] === 'x') {
                outputNumber =
                  outputNumber + inputNumber[arrInputDigits[outputIncrement]];
                outputIncrement++;
              } else {
                outputNumber = outputNumber + outputFormat[j];
              }
            }
            this.logger.logTrace(
              'Dynamics - Home : END : Formatting Phone Number. Input Number : ' +
              inputNumber +
              '. Configured Format : ' +
              JSON.stringify(phoneNumberFormat) +
              '. Output Number : ' +
              outputNumber
            );
            return outputNumber;
          }
        }
      }
    } catch (error) {
      this.logger.logError(
        'Dynamics - Home : ERROR : Formatting Phone Number. Input Number : ' +
        inputNumber +
        '. Configured Format : ' +
        JSON.stringify(phoneNumberFormat) +
        '. Error Information : ' +
        JSON.stringify(error)
      );
    }
    this.logger.logTrace(
      'Dynamics - Home : END : Formatting Phone Number. Input Number : ' +
      inputNumber +
      '. Configured Format : ' +
      JSON.stringify(phoneNumberFormat) +
      '. Output Number : ' +
      inputNumber
    );
    return inputNumber;
  }

  public checkIfRecentActivitiesExist() {
    try {
      return this.storageService.recentScenarioIdList.length > 0 ? true : false;
    } catch (error) {
      this.logger.logError(
        'Dynamics - Home : ERROR : Checking if Recent Activities Exist. Error Information : ' +
        JSON.stringify(error)
      );
    }
  }

  protected async removeLocalStorageOnLogout(reason?: string): Promise<any> {
    this.storageService.isLogout = true;
    for (const activityId in this.storageService.activityList) {
      if (this.storageService.activityList[activityId].IsActive) {
        await this.saveActivity(this.storageService.activityList[activityId], true, true);
      }
    }
    localStorage.clear();
  }

  protected async sendActivityDetails(activity: IActivity) {
    try {
      const tempActivity = {
        messageType: 'Activity',
        WhoObject: activity.WhoObject,
        WhatObject: activity.WhatObject,
        ChannelType: activity.ChannelType,
        CallDurationInSeconds: activity.CallDurationInSeconds,
        Subject: activity.Subject,
        Description: activity.Description,
        Status: activity.Status,
        ActivityId: activity.ActivityId,
        scenarioId: activity.ScenarioId,
        CadFields: activity.CadFields,
        IsActive: activity.IsActive,
      };

      const message = {
        sourceSystem: 'DaVinci',
        messageToSend: tempActivity,
        timePublished: new Date().toLocaleTimeString(),
      };

      const event = {
        contactDetails: message,
      };

      const response = await this.bridgeEventsService.sendEvent(
        'sendContactDetailsInfo',
        event
      );
      this.logger.logDebug(
        'Dynamics - Home : Received send Activity Details response from bridge. Scenario ID : ' +
        activity.ScenarioId +
        ' ' +
        JSON.stringify(response)
      );
    } catch (error) {
      this.logger.logError(
        'Dynamics - Home : ERROR : send Activity Details . More Info : ' +
        JSON.stringify(error)
      );
    }
  }

  protected async sendInteractionDetails(interaction: api.IInteraction) {
    try {
      const tempInteraction = {
        messageType: 'Interaction',
        channelType: api.CHANNEL_TYPES[interaction.channelType],
        state: api.INTERACTION_STATES[interaction.state],
        details: interaction.details,
        interactionId: interaction.interactionId,
        scenarioId: interaction.scenarioId,
        direction:
          interaction.direction === api.INTERACTION_DIRECTION_TYPES.Inbound
            ? 'Inbound'
            : interaction.direction === api.INTERACTION_DIRECTION_TYPES.Outbound
              ? 'Outbound'
              : 'Internal',
        userFocus: interaction.userFocus,
      };

      const message = {
        sourceSystem: 'DaVinci',
        messageToSend: tempInteraction,
        timePublished: new Date().toLocaleTimeString(),
      };

      const event = {
        contactDetails: message,
      };

      const response = await this.bridgeEventsService.sendEvent(
        'sendContactDetailsInfo',
        event
      );
      this.logger.logDebug(
        'Dynamics - Home : Received send Interaction Details response from bridge. Scenario ID : ' +
        interaction.scenarioId +
        ' ' +
        JSON.stringify(response)
      );
    } catch (error) {
      this.logger.logError(
        'Dynamics - Home : ERROR : send Interaction Details . More Info : ' +
        JSON.stringify(error)
      );
    }
  }

  protected async onInteraction(
    interaction: api.IInteraction
  ): Promise<api.SearchRecords> {
    this.logger.logDebug(
      'Dynamics - Home : Interaction received: ' + JSON.stringify(interaction)
    );
    this.logger.logInformation(
      'Dynamics - Home : Interaction received. Scenario ID : ' +
      interaction.scenarioId +
      ' . Interaction State : ' +
      interaction.state
    );
    try {
      const scenarioId = interaction.scenarioId;
      if (
        interaction.state !== api.INTERACTION_STATES.Disconnected &&
        this.storageService.recentActivityListContains(scenarioId)
      ) {
        this.storageService.removeRecentActivity(scenarioId);
      }
      let isNewScenarioId = false;
      let isCTDNumber = false;
      this.storageService.updateCadFields(
        interaction,
        this.cadActivityMap,
        this.cadDisplayConfig
      );

      if (interaction.state == INTERACTION_STATES.Alerting || interaction.state == INTERACTION_STATES.Connected) {

        const updatedSubject = this.buildSubjectText(interaction);

        if (this.storageService.activityList[interaction.scenarioId] != null &&
          this.storageService.activityList[interaction.scenarioId] != undefined &&
          !this.storageService.activityList[interaction.scenarioId].IsSubjectChanged &&
          this.storageService.activityList[interaction.scenarioId].InitialInteractionId == interaction.interactionId) {
          this.storageService.setSubject(updatedSubject, interaction.scenarioId);

          this.storageService.compareActivityFields(interaction.scenarioId);

          const changeEvent = {
            type: 'onSubjectChange',
            value: updatedSubject,
            scenarioID: interaction.scenarioId
          };

          this.changeNotify(JSON.stringify(changeEvent));
        }
      }

      if (
        this.storageService.recentActivityListContains(scenarioId) &&
        this.storageService.currentScenarioId !== scenarioId
      ) {
        // Validate if transcript is on a disconnect interaction after the first disconnect
        if (interaction.state === api.INTERACTION_STATES.Disconnected) {
          if (interaction.completedTranscript?.messages && interaction.completedTranscript?.messages.length > 0) {
            let completeTranscript = this.formatTranscription(interaction.completedTranscript);
            interaction.details.setField('transcription', '', '', completeTranscript);
            this.storageService.updateCadFields(
              interaction,
              this.cadActivityMap,
              this.cadDisplayConfig
            );
          }
        }
        this.saveActivity(scenarioId, true, this.enableAutoSave);
        return;
      }

      if (
        interaction.details &&
        interaction.details.fields &&
        interaction.details.fields.Phone &&
        interaction.details.fields.Phone.Value
      ) {
        interaction.details.fields.Phone.Value = this.formatPhoneNumber(
          interaction.details.fields.Phone.Value,
          this.phoneNumberFormat
        );
        const interactionDialString = this.phoneToDialString(interaction.details.fields.Phone.Value);

        // This interaction is marked as click to dial if
        // CTD was triggered and at least one of the following is true:
        //      - The CTD dialstring contains the interaction dialstring
        //      - The interaction dialstring contains the CTD dialstring
        //      - The interaction is NOT inbound

        isCTDNumber = this.clickToDialTriggered &&
          (interactionDialString.includes(this.lastSearchedCTDNumber) ||
            this.lastSearchedCTDNumber.includes(interactionDialString) ||
            interaction.direction != INTERACTION_DIRECTION_TYPES.Inbound);
      }

      isNewScenarioId = this.processIfNewScenario(interaction);

      if (
        interaction['userFocus'] ||
        (this.storageService.activeScenarioIdList.length === 1 &&
          this.storageService.activeScenarioIdList.indexOf(scenarioId) >= 0)
      ) {
        this.storageService.setCurrentScenarioId(scenarioId);
      }

      // Verify if Hold operations to calcualte the Hold Time
      this.storageService.updateInteractionDurationActivity(interaction.scenarioId, interaction.interactionId, interaction.state === api.INTERACTION_STATES.Disconnected);
      this.storageService.updateHoldInteractionActivityField(interaction.scenarioId, interaction.interactionId, interaction.state === api.INTERACTION_STATES.OnHold);

      if (interaction.state === api.INTERACTION_STATES.Disconnected) {
        if (interaction.completedTranscript?.messages && interaction.completedTranscript?.messages.length > 0) {
          let completeTranscript = this.formatTranscription(interaction.completedTranscript);
          interaction.details.setField('transcription', '', '', completeTranscript);
          this.storageService.updateCadFields(
            interaction,
            this.cadActivityMap,
            this.cadDisplayConfig
          );
        }
        if (isCTDNumber) {
          this.clickToDialTriggered = false;
          this.lastSearchedCTDNumber = '';
        }
        this.deleteExistingScenario(interaction);
      } else if (
        !(
          interaction.state === api.INTERACTION_STATES.Alerting &&
          this.screenpopOnAlert === false
        )
      ) {
        if (!this.storageService.searchRecordList[scenarioId]) {
          if (isCTDNumber) {
            this.clickToDialTriggered = false;
            this.updateClickToDialList(scenarioId);
          } else {
            const searchRecord = await this.searchAndScreenpop(
              interaction,
              isNewScenarioId
            );


            this.storageService.setsearchRecordList(
              searchRecord.toJSON(),
              scenarioId
            );
            this.logger.logDebug(
              'Dynamics - Home : END : Interaction received: ' +
              JSON.stringify(interaction)
            );

            return searchRecord;
          }
        }
      }
    } catch (error) {
      this.logger.logError(
        'Dynamics - Home : ERROR : On Interaction. More Info : ' +
        JSON.stringify(error)
      );
    } finally {
      this.sendInteractionDetails(interaction);
    }

    this.logger.logDebug(
      'Dynamics - Home : END : Interaction received: ' +
      JSON.stringify(interaction)
    );
    return;
  }

  private async searchAndScreenpop(
    interaction: api.IInteraction,
    isNewScenarioId: boolean
  ) {
    if (this.shouldPreformScreenpop(interaction, isNewScenarioId, this.searchLayout.enableMultiSession)) {
      this.logger.logInformation(
        'Dynamics - Home : Screen pop on interaction. Scenario ID : ' +
        interaction.scenarioId
      );
      this.logger.logDebug(
        'Dynamics - Home : Screen pop on interaction. Interaction Info : ' +
        JSON.stringify(interaction)
      );
      // Type field should be populated to allow for appropriate activity creation
      // "If (interaction has no type, or the type is the empty string) AND (channeltype is not null or undefined) then insert type."
      if ((!interaction.details ||
        !interaction.details.type ||
        interaction.details.type === '') &&
        interaction.channelType != null) {
        interaction.details.type = CHANNEL_TYPES[interaction.channelType];
      }
      const records = await this.preformScreenpop(interaction);
      this.logger.logDebug(
        'Dynamics - Home : Screen pop on interaction. Results : ' +
        JSON.stringify(records)
      );
      return records;
    } else {
      this.logger.logInformation(
        'Dynamics - Home : Search on interaction. Scenario ID : ' +
        interaction.scenarioId
      );
      this.logger.logDebug(
        'Dynamics - Home : Search on interaction. Interaction Info : ' +
        JSON.stringify(interaction)
      );
      const event = this.generateEventForScreenpop(interaction);
      event['search'] = true;
      event['interaction'] = interaction;


      const screenpopResult = await this.bridgeEventsService.sendEvent(
        'search',
        event
      );
      const records = this.formatCrmResults(screenpopResult);
      this.logger.logDebug(
        'Dynamics - Home : Search on interaction. Results after formatting : ' +
        JSON.stringify(records)
      );
      return records;
    }
  }

  protected saveAndStoreActivity(scenarioId: string) {
    this.saveActivity(scenarioId, true, this.enableAutoSave);
    this.storageService.onInteractionDisconnect(
      scenarioId,
      this.enableAutoSave
    );
    delete this.scenarioInteractionMappings[scenarioId];
  }

  protected async deleteExistingScenario(interaction: api.IInteraction): Promise<void> {
    try {
      this.logger.logDebug(
        'Dynamics - Home : START : Removing Scenario ID : ' +
        interaction.scenarioId
      );
      this.lastClickToDialIActivityDetails = null;
      if (this.scenarioInteractionMappings[interaction.scenarioId]) {
        delete this.scenarioInteractionMappings[interaction.scenarioId][
          interaction.interactionId
        ];

        if (
          Object.keys(this.scenarioInteractionMappings[interaction.scenarioId])
            .length === 0 && this.delayActivitySave > 0
        ) {
          await new Promise<void>((resolve, reject) => {
            this.logger.logDebug(
              'Dynamics  - Home : START : Delaying Removing Activity. Scenario ID : ' +
              interaction.scenarioId
            );
            const timer = setTimeout(() => {
              resolve();
            }, this.delayActivitySave);
          });
        }

        if (
          Object.keys(this.scenarioInteractionMappings[interaction.scenarioId])
            .length === 0
        ) {
          this.saveAndStoreActivity(interaction.scenarioId);
        }
      } else {
        this.logger.logInformation(
          'Dynamics - Home : END : Scenario ID : ' +
          interaction.scenarioId
        );
      }
    } catch (error) {
      this.logger.logError(
        'Dynamics - Home : ERROR : Deleting existing Scenario. Scenario ID : ' +
        interaction.scenarioId +
        '. Interaction Info : ' +
        JSON.stringify(interaction) +
        '. Error Information : ' +
        JSON.stringify(error)
      );
    }
  }

  protected processIfNewScenario(interaction: api.IInteraction): boolean {
    try {
      this.logger.logTrace(
        'Dynamics - Home : START : Checking if the interaction is new or existing. Interaction Info : ' +
        JSON.stringify(interaction)
      );
      if (
        !this.scenarioInteractionMappings.hasOwnProperty(
          interaction.scenarioId
        ) &&
        interaction.state !== api.INTERACTION_STATES.Disconnected &&
        (interaction.state !== api.INTERACTION_STATES.Alerting || this.screenpopOnAlert)
      ) {
        this.scenarioInteractionMappings[interaction.scenarioId] = {};
        this.scenarioInteractionMappings[interaction.scenarioId][
          interaction.interactionId
        ] = true;
        if (
          this.storageService.activeScenarioIdList.indexOf(
            interaction.scenarioId
          ) < 0
        ) {
          if (this.enableCallActivity) {
            this.storageService.addActivity(this.createActivity(interaction));
            this.saveActivity(
              interaction.scenarioId,
              false,
              this.enableAutoSave
            );
          }
        }
        this.logger.logInformation(
          'Dynamics - Home : New Scenario with Scenario ID : ' +
          interaction.scenarioId
        );
        this.logger.logTrace(
          'Dynamics - Home : END : Checking if the interaction is new or existing. Interaction Info : ' +
          JSON.stringify(interaction)
        );
        return true;
      } else if (
        this.scenarioInteractionMappings.hasOwnProperty(interaction.scenarioId) &&
        !this.scenarioInteractionMappings[interaction.scenarioId].hasOwnProperty(interaction.interactionId) &&
        interaction.state !== api.INTERACTION_STATES.Disconnected
      ) {
        this.logger.logTrace(
          'Dynamics - Home : Start : Found an existing scenario with no Interaction. Interaction Info : ' +
          JSON.stringify(interaction)
        );
        this.scenarioInteractionMappings[interaction.scenarioId][
          interaction.interactionId
        ] = true;
      } else if (interaction.state === api.INTERACTION_STATES.Disconnected) {
        if (!this.storageService.recentActivityListContains(interaction.scenarioId)) {
          this.scenarioInteractionMappings[interaction.scenarioId] = {};
          this.scenarioInteractionMappings[interaction.scenarioId][
            interaction.interactionId
          ] = true;
        }
      }
      this.logger.logTrace(
        'Dynamics - Home : END : Checking if the interaction is new or existing. Interaction Info : ' +
        JSON.stringify(interaction)
      );
      return false;
    } catch (error) {
      this.logger.logError(
        'Dynamics - Home : ERROR : Checking if the interaction is new or existing. Interaction Info : ' +
        JSON.stringify(interaction) +
        '. Error Information : ' +
        JSON.stringify(error)
      );
    }
  }

  protected buildSubjectText(interaction: api.IInteraction) {
    this.logger.logTrace(
      'Dynamics - Home : START : Building Subject Text. Interaction Info : ' +
      JSON.stringify(interaction)
    );
    let subjectText = '';
    try {
      const channelType = api.CHANNEL_TYPES[interaction.channelType];
      if (interaction.details.fields) {
        const fields = interaction.details.fields;
        if (fields.Email) {
          subjectText = `${channelType}[${fields.Email.Value}]`;
        } else if (this.storageService.subject) {
          let defaultSubject = this.storageService.subject;

          defaultSubject = defaultSubject.replace(/{(.*?)}/gi, function (x) {
            let replacedKey = x.replace(/{/gi, '');
            replacedKey = replacedKey.replace(/}/gi, '');

            let replacedValue = '';
            if (interaction.details.fields[replacedKey]) {
              replacedValue = ' ' + interaction.details.fields[replacedKey].Value + ' ';
            }

            return replacedValue;
          });

          subjectText = defaultSubject.replace(/{/gi, '');
          subjectText = subjectText.replace(/}/gi, '');
          subjectText = subjectText.trim();
          subjectText = subjectText.replace(/\s\s+/g, ' ');
        } else if (fields.Phone) {
          if (channelType === 'Telephony') {
            subjectText = `${'Call'}[${fields.Phone.Value}]`;
          } else {
            subjectText = `${channelType}[${fields.Phone.Value}]`;
          }
        } else if (fields.FullName) {
          subjectText = `${channelType}[${fields.FullName.Value}]`;
        }
      }
      this.logger.logInformation(
        'Dynamics - Home : Subject text for Scenario ID : ' +
        interaction.scenarioId +
        ' is ' +
        subjectText
      );
      this.logger.logTrace(
        'Dynamics - Home : END : Building Subject Text. Interaction Info : ' +
        JSON.stringify(interaction)
      );
    } catch (error) {
      this.logger.logError(
        'Dynamics - Home : ERROR : Creating new activity. Scenario ID : ' +
        interaction.scenarioId +
        '. Interaction Info : ' +
        JSON.stringify(interaction) +
        '. Error Information : ' +
        JSON.stringify(error)
      );
    }
    return subjectText;
  }

  protected formatCrmResults(crmResults: ICrmEntity[]): api.SearchRecords {
    try {
      this.logger.logTrace(
        'Dynamics - Home : START : Formatting CRM Results. CRM Results : ' +
        JSON.stringify(crmResults)
      );
      crmResults = crmResults || [];

      const result = new api.SearchRecords();

      if (
        /*typeof crmResults === 'object'*/ crmResults !== null &&
        crmResults.length
      ) {
        crmResults.forEach((element) => {
          const entity = element.entity;
          const record = new api.RecordItem(
            entity.Id,
            entity.Type,
            entity.DisplayName
          );
          const searchLayoutObject = this.searchLayout.objects.find(
            (object) => entity.Type === object.objectName
          );

          if (searchLayoutObject) {
            for (const key of Object.keys(entity)) {
              if (key !== 'Id' && key !== 'Type' && key !== 'DisplayName') {
                const phoneField = searchLayoutObject.phoneFields.find(
                  (field) => field.APIName === key
                );
                if (phoneField) {
                  if (!record.getPhone()) {
                    record.setPhone(
                      phoneField.APIName,
                      phoneField.DisplayName,
                      entity[key]
                    );
                  } else if (!record.getOtherPhone()) {
                    record.setOtherPhone(
                      phoneField.APIName,
                      phoneField.DisplayName,
                      entity[key]
                    );
                  } else {
                    record.setField(
                      phoneField.APIName,
                      phoneField.APIName,
                      phoneField.DisplayName,
                      entity[key]
                    );
                  }
                } else {
                  const objectField = searchLayoutObject.objectFields.find(
                    (field) => field.APIName === key
                  );
                  if (objectField) {
                    record.setField(
                      objectField.APIName,
                      objectField.APIName,
                      objectField.DisplayName,
                      entity[key]
                    );
                  }
                }
              }
            }
          }
          result.addSearchRecord(record);
        });
      }
      this.logger.logTrace(
        'Dynamics - Home : END : Formatting CRM Results. CRM Results : ' +
        JSON.stringify(crmResults)
      );
      return result;
    } catch (e) {
      this.logger.logError(
        'Dynamics - Home : ERROR : Formatting CRM Results. CRM Results : ' +
        JSON.stringify(crmResults) +
        '. Error Information : ' +
        JSON.stringify(e)
      );
      return null;
    }
  }

  protected createActivity(interaction: api.IInteraction): IActivity {
    try {
      this.logger.logDebug(
        'Dynamics - Home : START : Creating new Activity. Scenario ID : ' +
        interaction.scenarioId
      );
      const date = new Date();
      const activity: IActivity = {
        WhoObject: {
          objectType: '',
          displayName: '',
          objectName: '',
          objectId: '',
          url: '',
        },
        WhatObject: {
          objectType: '',
          displayName: '',
          objectName: '',
          objectId: '',
          url: '',
        },
        PhoneNumber: '',
        Subject: this.buildSubjectText(interaction),
        ChannelType: api.CHANNEL_TYPES[interaction.channelType],
        CallDurationInSeconds: 0,
        NumberOfHolds: 0,
        HoldDurationInSeconds: 0,
        HoldDurationOnInteractions: {},
        DurationOnInteractions: {},
        DirectionCode: interaction.direction,
        Description: '',
        Status: 'Open',
        TimeStamp: date,
        ActivityId: '',
        ScenarioId: interaction.scenarioId,
        InitialInteractionId: interaction.interactionId,
        CadFields: {},
        IsActive: true,
        IsProcessing: false,
        IsUnSaved: false,
        IsSubjectChanged: false,
        IsRecentWorkItemLoading: false,
        LastUpdated: date
      };
      const phoneField = interaction.details.fields.Phone;
      if (phoneField && phoneField.Value) {
        activity.PhoneNumber = phoneField.Value;
      }
      for (const key in this.cadActivityMap) {
        if (interaction.details.fields[key]) {
          if (!activity.CadFields) {
            activity.CadFields = {};
          }
          activity.CadFields[this.cadActivityMap[key]] =
            interaction.details.fields[key].Value;
        }
      }
      this.logger.logDebug(
        'Dynamics - Home : New activity Info : ' + JSON.stringify(activity)
      );
      this.logger.logDebug(
        'Dynamics - Home : END : Creating new Activity. Scenario ID : ' +
        interaction.scenarioId
      );
      return activity;
    } catch (error) {
      this.logger.logError(
        'Dynamics - Home : ERROR : Creating new activity. Scenario ID : ' +
        interaction.scenarioId +
        '. Error Information : ' +
        JSON.stringify(error)
      );
    }
  }

  protected async changeNotify(eventString): Promise<string> {
    try {
      const changeEvent = JSON.parse(eventString);
      const activity = this.storageService.getActivity(changeEvent.scenarioID);
      const tempActivity = {
        NotifyType: changeEvent.type,
        WhoObject: activity.WhoObject,
        WhatObject: activity.WhatObject,
        ChannelType: activity.ChannelType,
        CallDurationInSeconds: activity.CallDurationInSeconds,
        Subject: activity.Subject,
        Description: activity.Description,
        Status: activity.Status,
        ActivityId: activity.ActivityId,
        ScenarioId: activity.ScenarioId,
        CadFields: activity.CadFields,
        IsActive: activity.IsActive,
      };

      this.bridgeEventsService.sendEvent('activityChangeNotify', tempActivity);

      return Promise.resolve('success');
    } catch (error) {
      this.logger.logError(
        'Dynamics - Home : ERROR : Change Notify Activity. event : ' +
        eventString +
        '. Error Information : ' +
        JSON.stringify(error)
      );
    }
  }

  protected async saveActivity(
    scenarioId,
    isComplete = false,
    saveToCRM = true
  ): Promise<string> {
    let activity;
    try {
      this.logger.logInformation(
        'Dynamics  - Home : START : Saving Activity to CRM. Scenario ID : ' +
        scenarioId
      );

      activity = this.storageService.getActivity(scenarioId);
      if (activity && activity.IsActive && isComplete) {
        this.storageService.updateTotalInteractionTime(scenarioId, this.cadActivityMap);
        this.storageService.setActivityField(scenarioId, 'IsActive', false);
        this.storageService.updateTotalHoldTime(scenarioId, this.cadActivityMap);
      }
      if (!activity || !saveToCRM) {
        return;
      }

      const status = isComplete ? 'Completed' : 'Not Completed';
      this.storageService.setActivityField(scenarioId, 'Status', status);

      this.logger.logDebug(
        'Dynamics - Home : Activity Info to be sent to bridge. Scenario ID : ' +
        scenarioId +
        '. Activity Info : ' +
        JSON.stringify(activity)
      );

      activity = await this.bridgeEventsService.sendEvent(
        'saveActivity',
        activity
      );

      this.storageService.setActivityField(scenarioId, 'ActivityId', activity.ActivityId);

      this.logger.logDebug(
        'Dynamics - Home : Received Activity Info from bridge. Scenario ID : ' +
        scenarioId +
        '. Activity Info : ' +
        JSON.stringify(activity)
      );

      activity = this.storageService.getActivity(scenarioId);

      this.storageService.activityList[scenarioId].IsProcessing = false;
      this.storageService.updateActivityFields(scenarioId);
      this.storageService.compareActivityFields(scenarioId);
      return Promise.resolve(activity.ActivityId);
    } catch (error) {
      this.storageService.activityList[scenarioId].IsProcessing = false;
      api.sendNotification(
        'Call activity save failed.',
        api.NOTIFICATION_TYPE.Error
      );
      this.logger.logError(
        'Dynamics - Home : ERROR : Saving Activity to CRM. Scenario ID : ' +
        scenarioId +
        '. Error Information : ' +
        JSON.stringify(error)
      );
    } finally {
      if (activity && saveToCRM) {
        this.sendActivityDetails(activity);
      }
    }
  }

  protected async getRecentWorkItem(scenarioId): Promise<void> {
    try {
      this.logger.logInformation(
        'Dynamics - Home : START : Recent Work Item Details from CRM. Scenario ID : ' +
        scenarioId
      );
      const activity = this.storageService.getActivity(scenarioId);
      const recentWorkItem = await this.bridgeEventsService.sendEvent(
        'getActivity',
        activity
      );
      this.storageService.updateRecentWorkItem(
        recentWorkItem,
        scenarioId,
        this.activityLayout
      );
      this.storageService.activityList[scenarioId].IsRecentWorkItemLoading =
        false;
      this.logger.logInformation(
        'Dynamics - Home : END : Recent Work Item Details from CRM. Scenario ID : ' +
        scenarioId
      );
    } catch (error) {
      this.storageService.activityList[scenarioId].IsRecentWorkItemLoading =
        false;
      api.sendNotification(
        'Error Retrieving Activity Details',
        api.NOTIFICATION_TYPE.Error
      );
      this.logger.logError(
        'Dynamics - Home : ERROR : Recent Work Item Details from CRM. Scenario ID : ' +
        scenarioId +
        '. Error Information : ' +
        JSON.stringify(error)
      );
    }
  }

  protected async getActivityLayout() {
    try {
      this.logger.logTrace(
        'Dynamics - Home : START : Fetching Activity Layout'
      );
      this.activityLayout = {};
      for (const item in CHANNEL_TYPES) {
        if (isNaN(Number(item))) {
          this.activityLayout[item] = {};
          this.activityLayout[item]['APIName'] = 'phonecall';
          this.activityLayout[item]['Fields'] = [
            '_regardingobjectid_value',
            'phonecall_activity_parties',
            'Subject',
            'Description',
          ];
          this.activityLayout[item]['LookupFields'] = {
            _regardingobjectid_value: 'WhatObject',
            phonecall_activity_parties: 'WhoObject',
          };
        }
      }
      this.logger.logDebug(
        'Dynamics - Home : Activity Layout information : ' +
        JSON.stringify(this.activityLayout)
      );
      this.logger.logTrace('Dynamics - Home : END : Fetching Activity Layout');
    } catch (error) {
      this.logger.logError(
        'Dynamics - Home : ERROR : Fetching Activity Layout. More information : ' +
        JSON.stringify(error)
      );
    }
  }

  protected agentSelectedCallerInformation(object) {
    this.logger.logDebug(
      'Dynamics - Home : START : Agent Selected Entity to screenpop. Entity ID : ' +
      object
    );
    object['interaction'] = { scenarioId: this.storageService.currentScenarioId };
    this.bridgeEventsService.sendEvent(
      'agentSelectedCallerInformation',
      object
    );
    this.logger.logDebug(
      'Dynamics - Home : END : Agent Selected Entity to screenpop. Entity ID : ' +
      object
    );
  }

  protected isToolbarVisible(): Promise<boolean> {
    throw new Error('Not Implemented!');
  }

  protected async getSearchLayout(): Promise<api.SearchLayouts> {
    throw new Error('Not Implemented!');
  }

  protected createNewEntity(type: string) {
    try {
      this.logger.logTrace(
        'Dynamics - Home : START : Quick Create Entity Type : ' + type
      );
      let param = {
        entityType: type,
      };
      const activity = this.storageService.getActivity();
      if (activity) {
        if (activity.PhoneNumber) {
          param['phoneNumber'] = activity.PhoneNumber;
        }
        if (type === 'contact') {
          param = this.storageService.RetrieveEntityFromWhatList(
            'account',
            param
          );
        } else if (type === 'incident') {
          param['topic'] = activity.Subject;
          param = this.storageService.RetrieveEntityFromWhatList(
            'account',
            param
          );
          param = this.storageService.RetrieveEntityFromWhatList(
            'contact',
            param
          );
        } else if (type === 'lead') {
          param['topic'] = activity.Subject;
        } else if (type === 'opportunity') {
          param['topic'] = activity.Subject;
          param = this.storageService.RetrieveEntityFromWhatList(
            'account',
            param
          );
          param = this.storageService.RetrieveEntityFromWhatList(
            'contact',
            param
          );
        }
      }
      this.logger.logDebug(
        'Dynamics - Home : Quick create request to bridge with params : ' +
        JSON.stringify(param)
      );
      this.bridgeEventsService.sendEvent('createNewEntity', param);
      this.logger.logTrace(
        'Dynamics - Home : END : Quick Create Entity Type : ' + param
      );
    } catch (error) {
      this.logger.logError(
        'Dynamics - Home : ERROR : Quick Create. Entity Type : ' +
        type +
        '. More Info : ' +
        JSON.stringify(error)
      );
    }
  }

  protected onFocusHandler(entities) {
    this.logger.logDebug('onFocusEvent START: ' + entities);
    api.clearContextualContacts();
    super.onFocusHandler(entities);
    this.logger.logDebug('onFocusEvent END');

    for (let i = 0; i < entities.length; i++) {
      const activityObject = this.buildActivityDetails(entities[i]);
      if (this.storageService.currentScenarioId) {
        this.storageService.updateWhoWhatLists(
          activityObject,
          this.storageService.currentScenarioId
        );

        this.sendActivityDetails(
          this.storageService.getActivity(this.storageService.currentScenarioId)
        );
      }
      if (this.storageService.workingRecentScenarioId) {
        this.storageService.updateWhoWhatLists(
          activityObject,
          this.storageService.workingRecentScenarioId
        );
      }
    }
  }

  @bind
  protected sendNotification(event: any) {
    api.sendNotification(event.notification, event.notificationType);
  }

  @bind
  protected customEvent(event: any) {
    // To be implemented when custom events use cases are handled or
    // To be pushed to DaVinci API for other apps to handle the events
    // Temporarly displayed as notification
    api.sendNotification(
      JSON.stringify(event.result),
      api.NOTIFICATION_TYPE.Information
    );
  }

  /**
   * Converts a phone number into a dialstring. Strips phone number
   * of all characters that are not numeric.
   */
  private phoneToDialString(phoneNum: string): string {
    return phoneNum.replace(/[^0-9]/g, '');
  }

  @bind
  protected clickToDialHandler(event: any) {
    try {
      if (event.records) {

        this.lastSearchedCTDNumber = this.phoneToDialString(event.number);
        const clickToDialRecord = event.records.entity
          ? JSON.stringify(event.records.entity.CRMinfos)
          : event.records;
        let recordsList = clickToDialRecord;
        if (typeof clickToDialRecord === 'string') {
          recordsList = JSON.parse(clickToDialRecord);
        }
        this.lastClickToDialRecords = this.formatCrmResults(recordsList);
        for (let i = 0; i < recordsList.length; i++) {
          this.lastClickToDialIActivityDetails = this.buildActivityDetails(
            recordsList[i]
          );
        }
        const numberToDial = this.clickToDialFormatPhoneNumber(event.number);
        this.clickToDialTriggered = true;
        api.clickToDial(numberToDial, this.lastClickToDialRecords);
      }
    } catch (error) {
      this.logger.logError('Dynamics - Home : ERROR : clickToDialHandler(). Error Information : ' + JSON.stringify(error));
    }

  }

  protected async processConfig(config: api.IAppConfiguration) {
    try {
      if (config.SearchLayout && config.SearchLayout.variables['InteractionSearchFields'] && typeof (config.SearchLayout.variables['InteractionSearchFields']) === 'string') {
        this.cadPopKeys = getCadPopKeys(
          config.SearchLayout &&
            config.SearchLayout.variables['InteractionSearchFields']
            ? String(config.SearchLayout.variables['InteractionSearchFields'])
            : ''
        );
      } else if (config.SearchLayout && config.SearchLayout.variables['InteractionSearchFields'] && typeof (config.SearchLayout.variables['InteractionSearchFields']) === 'object') {
        this.cadPopKeys = getCadPopKeys('', config.SearchLayout.variables['InteractionSearchFields']);
      }
      this.bridgeEventsService.sendEvent('sendCadPopKeys', this.cadPopKeys);
      this.maxRecordsDefault = <number>config.variables.maxRecordsDefault;
      if (
        config['variables'] &&
        config['variables']['screenpopOnAlerting'] !== undefined
      ) {
        this.screenpopOnAlert = (config['variables']['screenpopOnAlerting']) as boolean;
      }

      if (
        config['variables'] &&
        config['variables']['PhoneNumberSearchFormats'] !== undefined &&
        (config['variables']['PhoneNumberSearchFormats'] as string[])?.length > 0
      ) {
        for (const format of config['variables']['PhoneNumberSearchFormats'] as string[]) {
          if ((format.split('x').length - 1) !== 10) {
            this.logger.logError('Dynamics - Home : ERROR : processConfig(). Invalid phone number format : ' + format);
          } else {
            this.phoneNumberSearchFormats.push(format);
          }
        }

        this.bridgeEventsService.sendEvent('sendPhoneNumberSearchFormats', this.phoneNumberSearchFormats);
      }

      if (
        config['CallActivity'] &&
        config['CallActivity']['variables'] &&
        config['CallActivity']['variables']['EnableCallActivity'] !== undefined
      ) {
        this.enableCallActivity = <boolean>(
          config['CallActivity']['variables']['EnableCallActivity']
        );
      }
      if (
        config['CallActivity'] &&
        config['CallActivity']['variables'] &&
        config['CallActivity']['variables']['EnableAutoSave'] !== undefined
      ) {
        this.enableAutoSave = <boolean>(
          config['CallActivity']['variables']['EnableAutoSave']
        );
      }
      this.storageService.maxRecentItems = <Number>(
        (config['CallActivity']
          ? config['CallActivity']['variables']['MaxRecentItems']
          : config['variables']['MaxRecentItems'])
      );
      this.searchLayout = getSearchLayout(config);
      this.AddActivityToSearchLayout();
      this.clickToDialLayout = getClickToDialLayout(config);
      this.cadDisplayConfig = getCadDisplayConfig(config);
      this.storageService.displayCadData = this.cadDisplayConfig.DisplayCad;
      this.bridgeEventsService.sendEvent('setLayouts', [
        this.searchLayout,
        this.clickToDialLayout,
      ]);
    } catch (e) {
      this.logger.logError('Dynamics - Home : ERROR : processConfig(). Error Information : ' + JSON.stringify(e));
    }
  }

  protected AddActivityToSearchLayout() {
    const object: ISearchLayoutObject = {
      objectName: 'activity',
      objectFields: [],
      phoneFields: [],
    };
    const activityDisplayFields = {};
    activityDisplayFields['phonecall_activity_parties'] =
      'phonecall_activity_parties';
    activityDisplayFields[
      '_regardingobjectid_value@Microsoft.Dynamics.CRM.lookuplogicalname'
    ] = 'regardingobjectid_value@Microsoft.Dynamics.CRM.lookuplogicalname';
    activityDisplayFields['_regardingobjectid_value'] =
      '_regardingobjectid_value';
    activityDisplayFields['description'] = 'Description';
    activityDisplayFields['subject'] = 'Subject';

    if (activityDisplayFields) {
      for (const displayKey of Object.keys(activityDisplayFields)) {
        object.objectFields.push({
          DisplayName: activityDisplayFields[displayKey],
          APIName: displayKey,
        });
      }
    }
    this.searchLayout.objects.push(object);
  }

  protected async registerForAmcEvents() {
    await super.registerForAmcEvents();
    await api.registerContextualControls((event) => {
      return api.clickToDial(event.uniqueId);
    });
  }

  protected getUserInfoHandler(): Promise<string> {
    return this.bridgeEventsService.sendEvent('getUserInfo', null);
  }

  protected buildActivityDetails(entityOBJ): IActivityDetails {
    const entity = entityOBJ.entity;
    const activityObject: IActivityDetails = {
      objectType: '',
      displayName: '',
      objectName: '',
      objectId: '',
      url: '',
    };
    activityObject.objectType = entity.DisplayName;
    activityObject.objectId = entity.Id;
    const entityConfiguration = this.searchLayout.objects.filter(
      (e) => e.objectName === entity.DisplayName
    );
    if (
      entityConfiguration.length > 0 &&
      entityConfiguration[0].objectFields.length > 0
    ) {
      for (const field of entityConfiguration[0].objectFields) {
        if (field.APIName && entity[field.APIName]) {
          const displayName = field.APIName;
          activityObject.displayName = entity[displayName];
          break;
        }
      }
    }
    return activityObject;
  }

  protected getSecondsElapsed(startDate): number {
    try {
      this.logger.logLoop('Dynamics - Home : START : Get Seconds Elapsed');
      const EndDate = new Date();
      if (typeof startDate === 'string') {
        startDate = new Date(startDate);
      }
      this.logger.logLoop('Dynamics - Home : END : Get Seconds Elapsed');
      return Math.round((EndDate.getTime() - startDate.getTime()) / 1000);
    } catch (error) {
      this.logger.logError(
        'Dynamics - Home : ERROR : Get Seconds Elapsed. Start Date : ' +
        startDate +
        '. Error Information : ' +
        JSON.stringify(error)
      );
    }
  }

  @bind
  protected clickToDialEntitiesHandler(entities) {
    this.logger.logDebug('clickToDialEntitiesHandler START: ' + entities);
    const contacts: { [key: string]: IContextualContact } = {};
    entities.forEach((entity) => {
      entity = entity.entity;
      for (const objectLayout of this.clickToDialLayout.Entities) {
        if (objectLayout.Name === entity.Type) {
          for (const field of objectLayout.PhoneFields) {
            if (entity[field.APIName]) {
              contacts[entity[field.APIName]] = {
                uniqueId: '' + Object.keys(contacts).length,
                firstName: entity[field.APIName],
                channels: [],
              };
              break;
            }
          }
          break;
        }
      }
    });

    let isNew = false;
    for (const key in contacts) {
      if (
        !(
          this.contextualContacts[key] &&
          this.contextualContacts[key].uniqueId === contacts[key].uniqueId &&
          this.contextualContacts[key].firstName === contacts[key].firstName
        )
      ) {
        isNew = true;
        break;
      }
    }

    if (isNew) {
      this.contextualContacts = contacts;
      api.addContextualContacts(Object.values(contacts));
    }
    this.logger.logDebug('clickToDialEntitiesHandler END');
  }

  protected clickToDialFormatPhoneNumber(number: any) {
    const configuredInputFormats = Object.keys(
      this.clickToDialPhoneReformatMap
    );
    for (let i = 0; i < configuredInputFormats.length; i++) {
      let formatCheck = true;
      if (number.length === configuredInputFormats[i].length) {
        // Length of incoming number matches length of a configured input format
        // Now Validate # of X's in input/output
        const inputFormat = configuredInputFormats[i];
        const outputFormat =
          this.clickToDialPhoneReformatMap[configuredInputFormats[i]];
        const arrInputDigits = [];
        let outputNumber = '';
        let outputIncrement = 0;
        if (
          (inputFormat.match(/x/g) || []).length !==
          (outputFormat.match(/x/g) || []).length
        ) {
          continue;
        }
        if (
          (inputFormat.match(/\(/g) || []).length !==
          (number.match(/\(/g) || []).length
        ) {
          continue;
        }
        if (
          (inputFormat.match(/-/g) || []).length !==
          (number.match(/-/g) || []).length
        ) {
          continue;
        }
        for (let j = 0; j < inputFormat.length; j++) {
          if (inputFormat[j] === 'x') {
            arrInputDigits.push(j);
          } else if (inputFormat[j] !== '?' && number[j] !== inputFormat[j]) {
            formatCheck = false;
            break;
          }
        }
        if (formatCheck) {
          for (let k = 0; k < outputFormat.length; k++) {
            if (outputFormat[k] === 'x') {
              outputNumber =
                outputNumber + number[arrInputDigits[outputIncrement]];
              outputIncrement++;
            } else {
              outputNumber = outputNumber + outputFormat[k];
            }
          }
          return outputNumber;
        }
      }
    }
    return number;
  }

  protected updateClickToDialList(scenarioId: string): void {
    try {
      this.logger.logTrace(
        'Dynamics - Home : START : Update Click To Dial Who/What Lists. Scenario ID : ' +
        scenarioId
      );
      this.storageService.setsearchRecordList(
        this.lastClickToDialRecords.toJSON(),
        scenarioId
      );
      if (this.lastClickToDialIActivityDetails) {
        this.storageService.updateWhoWhatLists(
          this.lastClickToDialIActivityDetails,
          scenarioId
        );

        // notify the latest activity changes
        this.sendActivityDetails(this.storageService.getActivity(scenarioId));
      }
      this.logger.logTrace(
        'Dynamics - Home : END : Update Click To Dial Who/What Lists. Scenario ID : ' +
        scenarioId
      );
    } catch (error) {
      this.logger.logError(
        'Dynamics - Home : ERROR : Updating Click to Dial Who/What Lists. Scenario ID : ' +
        scenarioId +
        '. Error Information : ' +
        JSON.stringify(error)
      );
    }
  }

  protected formatTranscription(completeTranscript: api.ICompletedTranscript): string {
    let combinedTranscript = '';
    try {
      // Check if the cti sent the complete transcript built already
      if (
        completeTranscript.messages.length == 1 && 
        (
          completeTranscript.messages[0].context.username === undefined || 
          completeTranscript.messages[0].context.username === ''
        ))
      {
        combinedTranscript = completeTranscript.messages[0].data;
      }
      else {
        for (const transcription of completeTranscript.messages) {
          combinedTranscript = combinedTranscript + `${transcription.timestamp} ${transcription.context.firstName}:\n${transcription.data}\n`
        }
      }
    } catch (error)
    {
      this.logger.logError(
        'Dynamics - Home : ERROR : Formatting Completed Transcript . Error Information : ' +
        JSON.stringify(error)
      );
    }
    return combinedTranscript;
  }
}
